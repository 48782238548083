@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Capriola&display=swap"); */
/* --------------- reset all elements --------------- */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Helps with consistent box-sizing */
  font-family: "Roboto", sans-serif;
  line-height: 1;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "Capriola", sans-serif; */
  font-family: sans-serif;
  color: green;
}
a {
  text-decoration: none;
}
/* --------------- global variables --------------- */
:root {
  --color-light: rgba(59, 195, 126, 0.2);
  --color-danger: #dc4544;
  --color-primary: green;
  --color-btn: #42b983;
}
/* --------------- body and layouts --------------- */
body {
  background-color: #eceff1;
}
/* --------------- side nav --------------- */
.side-nav {
  background-color: #ffffff;
  width: 250px;
  padding: 0 10px 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  position: fixed;
  top: 0;
  height: 100vh;
  overflow-y: auto;
  z-index: 1;
  transition: left 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
}
.side-nav li {
  list-style-type: none;
}
.side-nav li ul {
  margin-left: 18px;
  border-left: 3px solid #e1f4ff;
  padding-left: 5px;
}
.side-nav a {
  display: flex;
  margin: 3px 2px;
  padding: 8px 3px;
  border-radius: 5px;
  color: #7b767c;
}
.side-nav a > svg {
  width: 30px;
  text-align: left;
}
.side-nav a:hover,
.side-nav a.active {
  background-color: #e1f4ff;
}
.side-nav a.active {
  color: #3e68d5;
  font-weight: bold;
}
.side-nav > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: white;
  padding: 10px 0;
}
.side-nav h3 {
  margin-top: 10px;
  margin-bottom: 5px;
}
/* --------------- top nav --------------- */
.top-nav {
  position: fixed;
  top: 0;
  padding: 10px;
  padding-left: 260px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  display: flex;
  justify-content: space-between;
}
.top-nav:last-child {
  text-align: right;
}
.top-nav > div:first-child button {
  margin-right: 6px;
}
.top-nav > div:last-child button {
  margin-left: 6px;
}
/* --------------- both nav --------------- */
.top-nav button,
.side-nav button {
  background-color: transparent;
  font-size: 22px;
  padding: 4px 8px;
  border-radius: 5px;
  background-color: var(--color-light);
  color: var(--color-primary);
  border: none;
  cursor: pointer;
}
/* --------------- main --------------- */
main {
  padding: 10px;
  margin-left: 250px;
  margin-top: 50px;
}
main .card {
  min-height: 83vh;
}
/* --------------- card --------------- */
.card {
  padding: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
/* --------------- overlay --------------- */
.overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  background: #232323;
  opacity: 0.5;
  display: none;
  top: 0;
  left: 0;
}
.overlay.show {
  display: block;
}
/* --------------- table --------------- */
.tbl-box {
  width: 100%;
  overflow: auto;
}
.tbl-box table {
  width: 100%;
  min-width: 800px;
  border-collapse: collapse;
}
.tbl-box table th {
  background-color: var(--color-light);
  color: var(--color-primary);
}
.tbl-box table th,
.tbl-box table td {
  border: 2px solid var(--color-light);
  padding: 6px 8px;
}
.tbl-box table td:last-child {
  text-align: center;
}
.tbl-box table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}
.tbl-box table tr:hover {
  background-color: var(--color-light);
}
.tbl-box table td > button {
  border: none;
  font-size: inherit;
  cursor: pointer;
  margin: 0 4px;
}
/* filter */
.tbl-filter {
  margin-bottom: 5px;
  display: flex;
}
.tbl-filter > div {
  flex: 1;
}
/* pagination */
.tbl-pagination {
  display: flex;
  margin-top: 5px;
  border: 1px solid var(--color-primary);
  width: min-content;
  border-radius: 5px;
}
.tbl-pagination button {
  padding: 5px 10px;
  font-size: 16px;
  font-weight: bold;
  background-color: var(--color-light);
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  cursor: pointer;
}
.tbl-pagination button.active {
  background-color: var(--color-primary);
  color: #ffffff;
}
/* --------------- form --------------- */
input,
select {
  padding: 5px 8px;
  border: 3px solid #dddddd;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
  color: #504958;
  background-color: white;
}
input:focus,
select:focus {
  outline: none;
  border: 3px solid var(--color-primary);
}
label {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  color: #212529;
}
.f-loading {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  align-items: center;
  height: 100%;
  color: var(--color-primary);
  font-size: 35px;
}
.f-loading > div {
  position: absolute;
  width: 100%;
  height: 100%;
}
.form > div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px 10px;
}
.form > div > p {
  display: flex;
  flex-direction: column;
}
.form > div > p input {
  width: 100%;
}
.form > div.c3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px 10px;
}
.form > div.c1 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px 10px;
}
.i-form > p {
  display: flex;
  align-items: center;
  margin: 10px 0;
  position: relative;
}
.i-form > p > svg {
  position: absolute;
  left: 10px;
}
.i-form > p > input {
  flex: 1;
  padding-left: 30px;
}

.error {
  border: 2px solid red;
}

/* --------------- toolbar --------------- */
.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  background-color: white;
}
.toolbar div button {
  background-color: transparent;
  font-size: 16px;
  padding: 4px 6px;
  border-radius: 4px;
  border: 3px solid var(--color-primary);
  color: var(--color-primary);
  cursor: pointer;
  margin-left: 5px;
  background-color: var(--color-light);
}
.toolbar div button:hover {
  background-color: var(--color-primary);
  color: white;
}
.toolbar div button:disabled {
  cursor: not-allowed;
  background-color: #eeeeee;
  border-color: #eeeeee;
  color: gray;
}
.popup .content .toolbar {
  border-bottom: 3px solid var(--color-primary);
  padding-bottom: 10px;
}
/* --------------- popup --------------- */
.popup {
  position: fixed;
  top: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
}
.popup .overlay {
  display: block;
  z-index: -1;
}
.popup .content {
  width: 100%;
  max-width: 768px;
  margin: 10px;
  background-color: white;
  padding: 15px;
  overflow: auto;
  position: relative;
  border-radius: 5px;
}
.popup #close-btn {
  position: absolute;
  right: 20px;
  font-size: 25px;
  cursor: pointer;
}
.popup.lg .content {
  max-width: 1024px;
}
.popup.xlg .content {
  max-width: 1280px;
}
.popup.sm .content {
  max-width: 480px;
}
/* --------------- login --------------- */
.login-cont {
  display: flex;
  padding: 10px;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-cont > div.card > h2,
.login-cont > h1 {
  text-align: center;
  margin-bottom: 10px;
}
.login-cont .card {
  width: 100%;
  max-width: 480px;
}
/* --------------- button --------------- */
.btn {
  background-color: transparent;
  padding: 5px 15px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: var(--color-btn);
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.btn:hover {
  background-color: var(--color-primary);
}
