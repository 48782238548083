/* --------------- media query --------------- */
@media (min-width: 1281px) {
}
@media (min-width: 1025px) and (max-width: 1280px) {
}
@media (min-width: 768px) and (max-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .tbl-filter input[type="search"] {
    width: 280px;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .tbl-filter input[type="search"] {
    width: 320px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
}

/* --------------- max width query --------------- */
@media (max-width: 1024px) {
}
@media (max-width: 767px) {
  .side-nav {
    left: -250px;
  }
  .top-nav {
    padding-left: 10px;
  }
  main {
    margin-left: 0;
  }
  .side-nav.show {
    left: 0;
  }
  .form > div,
  .form > div.c3 {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 480px) {
}
@media (max-width: 320px) {
}

@media (pointer: coarse) {
}

/* --------------- min width query --------------- */
@media (min-width: 768px) {
}
@media (min-width: 1025px) {
  .tbl-filter input[type="search"] {
    width: 480px;
  }
}
